import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const ClockTime = ({ username }) => {
  const getStorageKey = (key) => `clock-${username}-${key}`;

  const [clockInTime, setClockInTime] = useState(() => {
    const savedTime = localStorage.getItem(getStorageKey("clockInTime"));
    return savedTime ? new Date(savedTime) : null;
  });
  const [clockOutTime, setClockOutTime] = useState(() => {
    const savedTime = localStorage.getItem(getStorageKey("clockOutTime"));
    return savedTime ? new Date(savedTime) : null;
  });
  const [currentTime, setCurrentTime] = useState(null);
  const [breakInTime, setBreakInTime] = useState(() => {
    const savedTime = localStorage.getItem(getStorageKey("breakInTime"));
    return savedTime ? new Date(savedTime) : null;
  });
  const [totalBreakTime, setTotalBreakTime] = useState(() => {
    const savedBreakTime = localStorage.getItem(
      getStorageKey("totalBreakTime")
    );
    return savedBreakTime ? parseInt(savedBreakTime) : 0;
  });
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const resetClockData = () => {
    localStorage.removeItem(getStorageKey("clockInTime"));
    localStorage.removeItem(getStorageKey("clockOutTime"));
    localStorage.removeItem(getStorageKey("breakInTime"));
    localStorage.removeItem(getStorageKey("totalBreakTime"));
    localStorage.setItem(getStorageKey("date"), new Date().toISOString());
    setClockInTime(null);
    setClockOutTime(null);
    setBreakInTime(null);
    setTotalBreakTime(0);
    setIsOnBreak(false);
  };

  const checkAndResetForNewDay = () => {
    const savedDate = localStorage.getItem(getStorageKey("date"));
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date as 'YYYY-MM-DD'

    if (!savedDate || savedDate.split("T")[0] !== currentDate) {
      resetClockData(); // Reset clock data if dates don't match or not present
    }
  };

  const formatTime = (time) => {
    if (!time) return "N/A";
    const dateTime = new Date(time);
    if (isNaN(dateTime.getTime())) return "Invalid Date";

    return dateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  const formatDuration = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
  };

  const calculateWorkHours = () => {
    if (!clockInTime || !clockOutTime) return "N/A";

    const clockIn = new Date(clockInTime);
    const clockOut = new Date(clockOutTime);

    if (isNaN(clockIn.getTime()) || isNaN(clockOut.getTime())) return "N/A";

    const workDuration = clockOut - clockIn - totalBreakTime;
    return workDuration > 0 ? formatDuration(workDuration) : "N/A";
  };

  useEffect(() => {
    if (clockInTime && !clockOutTime) {
      const id = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
      setIntervalId(id);
      return () => clearInterval(id);
    }
  }, [clockInTime, clockOutTime]);

  useEffect(() => {
    if (!username) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/clock`,
          {
            params: { username },
          }
        );
        const record = response.data.record;

        setClockInTime(
          record.clockInTime ? new Date(record.clockInTime) : null
        );
        setClockOutTime(
          record.clockOutTime ? new Date(record.clockOutTime) : null
        );
        setBreakInTime(
          record.breakInTime ? new Date(record.breakInTime) : null
        );
        setTotalBreakTime(record.totalBreakTime || 0);
        setIsOnBreak(!!record.breakInTime && !record.breakOutTime);
      } catch (err) {
        setError(err.response?.data?.error || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchData();
    }
  }, [username]);

  useEffect(() => {
    checkAndResetForNewDay();

    const savedClockInTime = localStorage.getItem(getStorageKey("clockInTime"));
    const savedClockOutTime = localStorage.getItem(
      getStorageKey("clockOutTime")
    );

    const savedBreakInTime = localStorage.getItem(getStorageKey("breakInTime"));
    const savedTotalBreakTime = localStorage.getItem(
      getStorageKey("totalBreakTime")
    );

    if (savedClockInTime) setClockInTime(new Date(savedClockInTime));
    if (savedClockOutTime) setClockOutTime(new Date(savedClockOutTime));
    if (savedBreakInTime) setBreakInTime(new Date(savedBreakInTime));
    if (savedTotalBreakTime)
      setTotalBreakTime(parseInt(savedTotalBreakTime, 10));
  }, []);

  useEffect(() => {
    if (!username) return;

    // Store values in localStorage whenever they change
    if (clockInTime) {
      localStorage.setItem(
        getStorageKey("clockInTime"),
        clockInTime.toISOString()
      );
    } else {
      localStorage.removeItem(getStorageKey("clockInTime"));
    }

    if (clockOutTime) {
      localStorage.setItem(
        getStorageKey("clockOutTime"),
        clockOutTime.toISOString()
      );
    } else {
      localStorage.removeItem(getStorageKey("clockOutTime"));
    }

    if (breakInTime) {
      localStorage.setItem(
        getStorageKey("breakInTime"),
        breakInTime.toISOString()
      );
    } else {
      localStorage.removeItem(getStorageKey("breakInTime"));
    }

    localStorage.setItem(getStorageKey("totalBreakTime"), totalBreakTime);
  }, [clockInTime, clockOutTime, breakInTime, totalBreakTime, username]);

  const handleClockIn = async () => {
    if (!username) return;
    if (clockInTime) {
      Swal.fire({
        icon: "info",
        title: "Already Clocked In",
        text: "You are already clocked in!",
      });
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/clock/clock-in`,
        { username }
      );

      const { clockInTime } = response.data.record;
      if (clockInTime) {
        const clockInDate = new Date(clockInTime);
        setClockInTime(clockInDate);
        // localStorage.setItem("clockInTime", clockInTime);
        Swal.fire({
          icon: "success",
          title: "Clocked In",
          text: "You have successfully clocked in!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to clock in. Invalid clock-in time.",
        });
      }
    } catch (error) {
      console.error(
        "Error during Clock In:",
        error.response?.data || error.message
      );
      alert("Failed to clock in. Please try again.");
    }
  };

  const handleClockOut = async () => {
    if (!clockInTime) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You need to clock in before clocking out.",
      });
      return;
    }

    // const currentDate = new Date().toISOString().split("T")[0];
    // const clockOutDate = clockOutTime
    //   ? clockOutTime.toISOString().split("T")[0]
    //   : null;
    // if (clockOutDate && clockOutDate !== currentDate) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Invalid Action",
    //     text: "You cannot clock out for the previous day.",
    //   });
    //   return;
    // }
    if (!username) return;

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to clock out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, clock out!",
    });
    if (!result.isConfirmed) {
      return; // User canceled clock-out
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/clock/clock-out`,
        { username }
      );

      const { clockOutTime } = response.data.record;
      // const clockOut = new Date(response.data.clockOutTime);
      if (clockOutTime) {
        const formattedClockOutTime = new Date(clockOutTime);
        setClockOutTime(formattedClockOutTime);
        clearInterval(intervalId);
        Swal.fire({
          icon: "success",
          title: "Clocked Out",
          text: "Clocked out successfully!",
        });
      }
      // localStorage.setItem("clockOutTime", clockOut);
      else {
        alert("Invalid clock-out time received.");
      }
    } catch (error) {
      console.error(
        "Error during Clock Out:",
        error.response?.data || error.message
      );
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Failed to clock out. Please try again.",
      });
    }
  };

  const handleToggleBreak = async () => {
    if (!clockInTime) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You must clock in before starting a break.",
      });
      return;
    }
    if (clockOutTime) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You cannot take a break after clocking out.",
      });
      return;
    }

    if (isOnBreak) {
      // Break Out logic
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/clock/break-out`,
          { username }
        );
        const breakEndTime = new Date();
        const breakDuration = breakEndTime - breakInTime;
        setTotalBreakTime((prev) => prev + breakDuration);
        setBreakInTime(null);
        Swal.fire({
          icon: "success",
          title: "Break Ended",
          text: "You have successfully ended your break!",
        });
      } catch (error) {
        console.error(
          "Error toggling break: ",
          error.response?.data || error.message
        );
        alert("Failed to end break. Please try again.");
      }
    } else {
      // Break In logic
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/clock/break-in`,
          { username }
        );
        const breakStartTime = new Date();
        setBreakInTime(breakStartTime);
        Swal.fire({
          icon: "success",
          title: "Break Started",
          text: "You have successfully started your break!",
        });
      } catch (error) {
        console.error(
          "Error toggling break: ",
          error.response?.data || error.message
        );
        if (error.response?.data?.error === "You are already on a break") {
          Swal.fire({
            icon: "info",
            title: "Already on Break",
            text: "You are already on a break.",
          });
        } else {
          alert("Failed to start break. Please try again.");
        }
      }
    }
    setIsOnBreak(!isOnBreak); // Toggle break status
  };

  return (
    <div className="flex items-center justify-between   mb-4">
      <div className="flex items-center space-x-4">
        <button
          onClick={handleClockIn}
          disabled={!!clockInTime}
          className={`px-4 py-2 rounded text-white ${
            clockInTime
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-600"
          }`}
        >
          Clock In
        </button>
        <button
          onClick={handleClockOut}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        >
          Clock Out
        </button>
        <div className="flex items-center space-x-4">
          {/* <button
            onClick={handleClockToggle}
            className={`px-4 py-2 rounded text-white ${
              clockInTime
                ? "bg-red-500 hover:bg-red-600"
                : "bg-green-500 hover:bg-green-600"
            }`}
          >
            {clockInTime ? "Clock Out" : "Clock In"}
          </button> */}
          <button
            onClick={handleToggleBreak}
            className={`px-4 py-2 rounded text-white ${
              isOnBreak
                ? "bg-stone-950 hover:bg-blue-600"
                : "bg-blue-400 hover:bg-yellow-600"
            }`}
            // disabled={clockInTime || clockOutTime}
          >
            {isOnBreak ? "End Break" : "Start Break"}
          </button>
        </div>
      </div>

      {clockInTime && (
        <div className="flex flex-col items-center text-right">
          <p className="text-base font-medium">
            <strong>Clock-In Time:</strong> {formatTime(clockInTime)}
          </p>
          {/* <p className="text-base font-bold mt-2">
            Work Hours:{" "}
            {currentTime ? formatTime(currentTime) : "Calculating..."}
          </p> */}
          {isOnBreak && breakInTime && (
            <div className="mt-4">
              <p className="text-base">
                <strong>Break In Time:</strong> {formatTime(breakInTime)}
              </p>
            </div>
          )}
          {!isOnBreak && totalBreakTime > 0 && (
            <div className="">
              <p className="text-base">
                <strong> Total Break Time:</strong>{" "}
                {formatDuration(totalBreakTime)}
              </p>
            </div>
          )}
          {clockOutTime && (
            <>
              <p>
                <strong>Clock-Out Time:</strong> {formatTime(clockOutTime)}
              </p>
              <p>
                <strong>Total Working Hours:</strong> {calculateWorkHours()}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ClockTime;
