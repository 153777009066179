import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  FaUser,
  FaSearch,
  FaEllipsisV,
  FaPaperPlane,
  FaSmile,
  FaPaperclip,
  FaUsers,
} from "react-icons/fa";
const Chat = ({ username }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [channels, setChannels] = useState([
    { name: "general", employees: [] },
  ]);
  const [selectedChannel, setSelectedChannel] = useState("general");
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [unreadCounts, setUnreadCounts] = useState({});
  const [lastMessages, setLastMessages] = useState({});
  const messageContainerRef = useRef(null);
  const [showChannelMembers, setShowChannelMembers] = useState(false);
  const [selectedChannelMembers, setSelectedChannelMembers] = useState([]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchMessages();
    fetchEmployees();
    fetchChannels();
    const interval = setInterval(fetchMessages, 3000);
    return () => clearInterval(interval);
  }, [selectedChannel, selectedRecipient]);

  useEffect(() => {
    const initialUnreadCounts = {};
    channels.forEach((channel) => {
      initialUnreadCounts[channel.name] = 0;
    });
    employees.forEach((employee) => {
      initialUnreadCounts[employee] = 0;
    });
    setUnreadCounts(initialUnreadCounts);
  }, [channels, employees]);

  const fetchChannels = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/channels`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const userChannels = response.data.filter((channel) =>
        channel.employees.includes(username)
      );

      if (!userChannels.find((channel) => channel.name === "general")) {
        userChannels.unshift({ name: "general", employees: [] });
      }

      setChannels(userChannels);
      const initialUnreadCounts = {};
      userChannels.forEach((channel) => {
        initialUnreadCounts[channel.name] = 0;
      });
      setUnreadCounts((prev) => ({ ...prev, ...initialUnreadCounts }));
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };
  const fetchMessages = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URI}/chat`;
      let params = {};
      if (selectedRecipient) {
        params = {
          sender: username,
          recipientName: selectedRecipient,
        };
      } else if (selectedChannel) {
        params = { channelName: selectedChannel };
      }
      const response = await axios.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const newMessages = response.data;
      setMessages(newMessages);
      updateMessageStatus(newMessages);
      if (newMessages.length > 0) {
        const lastMessage = newMessages[newMessages.length - 1];
        if (lastMessage.sender !== username) {
          setEmployees((prevEmployees) => {
            const updatedEmployees = prevEmployees.filter(
              (employee) => employee !== lastMessage.sender
            );
            return [lastMessage.sender, ...updatedEmployees];
          });
        }
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/employees`
      );
      const employeesList = response.data
        .filter(
          (employee) =>
            employee.role === "employee" && employee.name !== username
        )
        .map((employee) => employee.name);

      // Add Admin at the beginning of the list
      const allUsers = ["Admin", ...employeesList];

      setEmployees(allUsers);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setEmployees([]);
    }
  };
  useEffect(() => {
    if (selectedChannel) {
      const lastSeen = JSON.parse(localStorage.getItem("lastSeen") || "{}");
      lastSeen[selectedChannel] = Date.now();
      localStorage.setItem("lastSeen", JSON.stringify(lastSeen));

      const counts = JSON.parse(localStorage.getItem("unreadCounts")) || {};
      counts[selectedChannel] = 0;
      localStorage.setItem("unreadCounts", JSON.stringify(counts));
      setUnreadCounts(counts);
    }
  }, []);

  const updateMessageStatus = (messages) => {
    const storedCounts = JSON.parse(localStorage.getItem("unreadCounts")) || {};
    const newUnreadCounts = { ...storedCounts };
    const newLastMessages = {};
    const lastSeen = JSON.parse(localStorage.getItem("lastSeen") || "{}");

    messages.forEach((message) => {
      const key = message.channelName || message.sender;
      if (message.sender === username) return;

      // Only count messages newer than last seen
      const lastSeenTime = lastSeen[key] || 0;
      const messageTime = new Date(message.timestamp).getTime();

      if (message.channelName) {
        if (
          message.channelName !== selectedChannel &&
          messageTime > lastSeenTime
        ) {
          newUnreadCounts[message.channelName] =
            (newUnreadCounts[message.channelName] || 0) + 1;
        }
      } else {
        if (
          message.sender !== selectedRecipient &&
          messageTime > lastSeenTime
        ) {
          newUnreadCounts[message.sender] =
            (newUnreadCounts[message.sender] || 0) + 1;
        }
      }

      newLastMessages[key] = {
        text: message.text,
        timestamp: message.timestamp,
      };
    });

    if (selectedChannel || selectedRecipient) {
      const key = selectedChannel || selectedRecipient;
      lastSeen[key] = Date.now();
      localStorage.setItem("lastSeen", JSON.stringify(lastSeen));
      newUnreadCounts[key] = 0;
    }

    localStorage.setItem("unreadCounts", JSON.stringify(newUnreadCounts));
    setUnreadCounts(newUnreadCounts);
    setLastMessages((prev) => ({
      ...prev,
      ...newLastMessages,
    }));
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) {
      alert("Please enter a message");
      return;
    }

    try {
      const messageData = {
        text: newMessage,
        sender: username,
        ...(selectedChannel && { channelName: selectedChannel }),
        ...(selectedRecipient && { recipientName: selectedRecipient }),
      };
      console.log("Sending message:", messageData);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/chat`,
        messageData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setMessages((prevMessages) => [...prevMessages, response.data]);

      // Update last messages
      if (selectedChannel) {
        setLastMessages((prev) => ({
          ...prev,
          [selectedChannel]: {
            text: newMessage,
            timestamp: response.data.timestamp,
          },
        }));
      } else if (selectedRecipient) {
        setLastMessages((prev) => ({
          ...prev,
          [selectedRecipient]: {
            text: newMessage,
            timestamp: response.data.timestamp,
          },
        }));
      }
      setNewMessage("");
      setTimeout(fetchMessages, 500);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  const handleChannelMembersClick = async (channelName) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/channels`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const channel = response.data.find((ch) => ch.name === channelName);
      if (channel) {
        setSelectedChannelMembers(channel.employees || []);
        setShowChannelMembers(true);
      }
    } catch (error) {
      console.error("Error fetching channel members:", error);
    }
  };
  const handleChannelSelect = (channelName) => {
    setSelectedChannel(channelName);
    setSelectedRecipient(null);
    setMessages([]);

    const lastSeen = JSON.parse(localStorage.getItem("lastSeen") || "{}");
    lastSeen[channelName] = Date.now();
    localStorage.setItem("lastSeen", JSON.stringify(lastSeen));

    const updatedCounts = {
      ...unreadCounts,
      [channelName]: 0,
    };
    setUnreadCounts(updatedCounts);
    localStorage.setItem("unreadCounts", JSON.stringify(updatedCounts));
  };

  const handleEmployeeSelect = (employeeName) => {
    setSelectedRecipient(employeeName);
    setSelectedChannel("");

    const lastSeen = JSON.parse(localStorage.getItem("lastSeen") || "{}");
    lastSeen[employeeName] = Date.now();
    localStorage.setItem("lastSeen", JSON.stringify(lastSeen));

    const updatedCounts = {
      ...unreadCounts,
      [employeeName]: 0,
    };
    setUnreadCounts(updatedCounts);
    localStorage.setItem("unreadCounts", JSON.stringify(updatedCounts));
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const filteredChannels = channels.filter((channel) =>
    channel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredEmployees = employees.filter((employee) =>
    employee.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const formatMessageText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary text-decoration-underline"
            onClick={(e) => e.stopPropagation()}
          >
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        {/* Sidebar */}
        <div className="col-md-3 bg-white p-2  mb-10 shadow-sm border-end">
          <div className="mb-3 position-relative">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="form-control rounded pe-4"
            />
            <FaSearch
              className="position-absolute top-50 end-3 translate-middle-y text-muted"
              size={16}
            />
            <i className="fa fa-search position-absolute top-50 end-3 translate-middle-y text-muted"></i>
          </div>

          <h6 className="fw-bold mb-3">Channels</h6>
          <div className="overflow-auto mb-4" style={{ maxHeight: "200px" }}>
            {filteredChannels.map((channel, index) => (
              <div key={index}>
                <div
                  onClick={() => handleChannelSelect(channel.name)}
                  className={`p-2 rounded mb-2 ${
                    selectedChannel === channel.name
                      ? "bg-light fw-bold"
                      : "text-dark"
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <span># {channel.name}</span>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      {unreadCounts[channel.name] > 0 && (
                        <span className="badge bg-success rounded-pill">
                          {unreadCounts[channel.name]}
                        </span>
                      )}
                      <div className="d-flex align-items-center gap-2">
                        {unreadCounts[channel.name] > 0 && (
                          <span className="badge bg-success rounded-pill">
                            {unreadCounts[channel.name]}
                          </span>
                        )}
                        {channel.name !== "general" && (
                          <FaUsers
                            className="text-muted"
                            size={16}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleChannelMembersClick(channel.name);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {lastMessages[channel.name] && (
                    <div className="d-flex justify-content-between mt-1">
                      <small className="text-muted text-truncate me-2">
                        {lastMessages[channel.name].text}
                      </small>
                      <small className="text-muted">
                        {formatTime(lastMessages[channel.name].timestamp)}
                      </small>
                    </div>
                  )}
                </div>

                {/* Add Channel Members Modal here */}
                {showChannelMembers && selectedChannel === channel.name && (
                  <div className="bg-white p-2 mb-2 rounded shadow-sm">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <small className="fw-bold">Channel Members</small>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowChannelMembers(false)}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="channel-members-list">
                      {selectedChannelMembers.map((member, idx) => (
                        <div
                          key={idx}
                          className="d-flex align-items-center mb-1"
                        >
                          <div
                            className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center me-2"
                            style={{
                              width: "24px",
                              height: "24px",
                              fontSize: "12px",
                            }}
                          >
                            {member.charAt(0).toUpperCase()}
                          </div>
                          <small>{member}</small>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <h6 className="fw-bold mb-3">Employees</h6>
          <div className="overflow-auto" style={{ maxHeight: "600px" }}>
            {filteredEmployees.map((employee, index) => (
              <div
                key={index}
                onClick={() => handleEmployeeSelect(employee)}
                className={`p-2 rounded mb-2 ${
                  selectedRecipient === employee
                    ? "bg-light fw-bold"
                    : "text-dark"
                }`}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div
                      className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center me-2"
                      style={{ width: "35px", height: "32px" }}
                    >
                      {employee.charAt(0).toUpperCase()}
                    </div>
                    <div>
                      <div>{employee}</div>
                      {lastMessages[employee] && (
                        <small className="text-muted text-truncate d-block">
                          {lastMessages[employee].text}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    {lastMessages[employee] && (
                      <small className="text-muted">
                        {formatTime(lastMessages[employee].timestamp)}
                      </small>
                    )}
                    {unreadCounts[employee] > 0 && (
                      <span className="badge bg-success rounded-pill mt-1">
                        {unreadCounts[employee]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Message Area */}
        <div className="col-md-9 mb-10 bg-light p-3">
          <h5 className="fw-bold mb-3 p-2">
            {selectedChannel ||
              selectedRecipient ||
              "Select a channel or recipient"}
          </h5>
          <div
            ref={messageContainerRef}
            className="overflow-auto bg-secondary-subtle p-4 rounded mb-2 flex-grow-1"
            style={{ height: "600px" }}
          >
            {messages.map((message) => (
              <div
                key={message._id}
                className={`d-flex mb-2 ${
                  message.sender === username
                    ? "justify-content-end"
                    : "justify-content-start"
                }`}
              >
                <div
                  className={`rounded shadow-sm p-2 ${
                    message.sender === username
                      ? "bg-success-subtle ms-auto"
                      : "bg-white"
                  }`}
                  style={{ maxWidth: "60%" }}
                >
                  {message.sender !== username && (
                    <span className="d-block fw-medium text-danger">
                      {message.sender}
                    </span>
                  )}
                  <p className="text-dark small">
                    {formatMessageText(message.text)}
                  </p>
                  <div className="d-flex justify-content-end align-items-center">
                    <span className="text-muted small">
                      {formatTime(message.timestamp)}
                    </span>
                    {message.sender === username && (
                      <svg
                        className="text-info"
                        width="14"
                        height="12"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M15.01 3.3L5.67 12.64L1 7.97"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {/* <div ref={messagesEndRef}></div> */}
          </div>
          <div className="bg-light p-3 rounded">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
              className="d-flex gap-2"
            >
              <input
                // ref={(input) => input && input.focus()}
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message"
                className="form-control rounded"
                rows="1"
                style={{ resize: "none" }}
              />
              <button
                type="submit"
                className="btn btn-success rounded-pill d-flex align-items-center justify-content-center"
              >
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 12h14M12 5l7 7-7 7"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
