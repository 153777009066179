import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { utils as XLSXUtils, write as XLSXWrite } from "xlsx";

const LeadsDashboard = ({ username }) => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [showLeadModal, setShowLeadModal] = useState(false);
  const [isCreatingLead, setIsCreatingLead] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLeadData, setFilteredLeadData] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    type: "",
    gender: "",
  });

  const headers = [
    "S.No.",
    "Name",
    "Contact",
    "Email",
    "Gender",
    "Type",
    "Status",
    "Description",
  ];
  const STATUS_OPTIONS = [
    "Pending",
    "Contacted",
    "In Progress",
    "Interested",
    "Not Interested",
    "In Future",
    "Won",
  ];

  useEffect(() => {
    const filtered = leadData.filter((lead) => {
      const matchesSearchQuery =
        lead.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lead.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lead.type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lead.gender?.toLowerCase().includes(searchQuery.toLowerCase());
      return (
        matchesSearchQuery &&
        (!filters.status || lead.status === filters.status) &&
        (!filters.type || lead.type === filters.type) &&
        (!filters.gender || lead.gender === filters.gender)
      );
    });
    setFilteredLeadData(filtered);
  }, [filters, leadData, searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/employees`
        );

        // Filter employees with 'role' as 'employee'
        const filteredEmployees = response.data.filter(
          (employee) => employee.role === "employee"
        );

        // Get the employee names
        const employeeNames = filteredEmployees.map(
          (employee) => employee.name
        );
        setEmployees(employeeNames);
      } catch (error) {
        toast.error(
          error?.response?.data && typeof error.response.data === "string"
            ? error.response.data
            : "Error fetching employees"
        );
      } finally {
        setLoading(false);
      }
    };
    const fetchLeads = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/leads`
        );
        const allLeads = response.data.leads || [];
        console.log(
          "Assign Values:",
          allLeads.map((lead) => lead.assign)
        );
        const filteredLeads = allLeads.filter(
          (lead) =>
            lead.assign &&
            lead.assign.trim().toLowerCase() === username.trim().toLowerCase()
        );
        console.log("Filtered Leads:", filteredLeads);
        setLeadData(filteredLeads);
      } catch (error) {
        toast.error(
          error?.response?.data && typeof error.response.data === "string"
            ? error.response.data
            : "Error fetching leads"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
    fetchLeads();
  }, [username]);

  const downloadExcel = () => {
    try {
      // Prepare data for export
      const exportData = filteredLeadData.map((lead, index) => ({
        "S.No.": index + 1,
        Name: lead.name,
        Contact: lead.contact,
        Email: lead.email || "N/A",
        Gender: lead.gender,
        Type: lead.type,
        Status: lead.status,
        Description: lead.description,
      }));
      const worksheet = XLSXUtils.json_to_sheet(exportData);
      const workbook = XLSXUtils.book_new();
      XLSXUtils.book_append_sheet(workbook, worksheet, "Leads");
      const excelBuffer = XLSXWrite(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `leads_${new Date().toISOString().split("T")[0]}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);

      // toast.success('Excel file downloaded successfully!');
    } catch (error) {
      console.error("Error downloading Excel:", error);
      toast.error("Failed to download Excel file");
    }
  };

  const handleLeadSubmit = async (e) => {
    e.preventDefault();

    const leadData = {
      name: e.target.name.value.trim(),
      contact: e.target.contact.value.trim(),
      email: e.target.email.value || null,
      description: e.target.description.value.trim(),
      gender: e.target.gender.value,
      assign: e.target.assign.value,
      status: e.target.status.value,
      type: e.target.type.value,
    };
    console.log("Lead Data:", leadData);

    try {
      setIsCreatingLead(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/leads`,
        leadData,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200 || response.status === 201) {
        console.log("Lead created successfully!", response.data);
        // Fetch updated leads after successful creation
        const leadsResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/leads`
        );
        const allLeads = leadsResponse.data.leads || [];
        const filteredLeads = allLeads.filter(
          (lead) =>
            lead.assign &&
            lead.assign.trim().toLowerCase() === username.trim().toLowerCase()
        );
        setLeadData(filteredLeads);

        Swal.fire({
          icon: "success",
          title: "Lead Created",
          text: "The lead has been created successfully!",
          confirmButtonText: "OK",
        }).then(() => {
          setShowLeadModal(false);
        });
      } else {
        console.error("Failed to create lead.");
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to create lead. Please try again.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error.message);
    } finally {
      setIsCreatingLead(false);
    }
  };

  const handleStatusChange = async (index, newStatus) => {
    const lead = leadData[index];

    // Store the original status in case we need to revert
    const originalStatus = lead.status;

    try {
      // Update UI immediately
      setLeadData((prevLeads) =>
        prevLeads.map((item, i) =>
          i === index ? { ...item, status: newStatus } : item
        )
      );

      // Make API call
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/leads/${lead._id}`,
        { status: newStatus }
      );

      if (response.data.success) {
        toast.success("Status updated successfully");

        // Update the local state with the response data
        setLeadData((prevLeads) =>
          prevLeads.map((item, i) =>
            i === index ? { ...item, ...response.data.lead } : item
          )
        );
      } else {
        // Revert to original status if update failed
        setLeadData((prevLeads) =>
          prevLeads.map((item, i) =>
            i === index ? { ...item, status: originalStatus } : item
          )
        );
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Status update failed:", error);
      // Revert to original status on error
      setLeadData((prevLeads) =>
        prevLeads.map((item, i) =>
          i === index ? { ...item, status: originalStatus } : item
        )
      );
      toast.error("Failed to update status");
    }
  };

  const handleDescriptionUpdate = async (index) => {
    const lead = leadData[index];

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/leads/${lead._id}`,
        { description: lead.description }
      );

      if (response.data.success) {
        toast.success("Description updated successfully");
        // Close the editing mode
        setExpandedDescriptions((prev) => prev.filter((i) => i !== index));
      }
    } catch (error) {
      console.error("Failed to update description:", error);
      alert("Failed to update description");
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-4 mb-4">
          <input
            type="text"
            placeholder="Search Lead"
            value={searchQuery}
            onChange={handleSearchChange}
            className="border border-black rounded px-4 py-2 mr-4 mt-5"
          />
          <select
            onChange={(e) => handleFilterChange("status", e.target.value)}
            value={filters.status}
            className="border border-black rounded px-2 py-2 mt-5"
          >
            <option value="">All Statuses</option>
            <option value="Pending">Pending</option>
            <option value="Contacted">Contacted</option>
            <option value="In Progress">In Progress</option>
            <option value="Intrested">Intrested</option>
            <option value="Not Intrested">Not Intrested</option>
            <option value="In Future">In Future</option>
            <option value="Won">Won</option>
            {/* Add other status options */}
          </select>
        </div>
        <div className="flex gap-4">
          <button
            onClick={downloadExcel}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
          >
            Download Excel
          </button>
          <button
            onClick={() => setShowLeadModal(true)}
            className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Create Lead
          </button>
        </div>
      </div>
      <div className="overflow-x-auto mt-6">
        <table className="min-w-full table-auto border-collapse border border-black">
          <thead>
            <tr className="bg-black">
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="border border-gray-300 text-white text-center px-4 py-2"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredLeadData.length > 0 ? (
              filteredLeadData.map((lead, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className="border border-black text-center px-2 py-2">
                    {index + 1}
                  </td>
                  <td className="border border-black px-4 py-2">{lead.name}</td>
                  <td className="border border-black text-center px-4 py-2">
                    {lead.contact}
                  </td>
                  <td className="border border-black text-center px-4 py-2">
                    {lead.email || "N/A"}
                  </td>
                  <td className="border border-black text-center px-4 py-2">
                    {lead.gender}
                  </td>
                  <td className="border border-black  text-center px-4 py-2">
                    {lead.type}
                  </td>
                  <td className="border border-black text-center px-4 py-2">
                    {/* {lead.status} */}
                    <select
                      value={lead.status || "Pending"}
                      onChange={(e) =>
                        handleStatusChange(index, e.target.value)
                      }
                      className="w-full p-2 border rounded"
                    >
                      {STATUS_OPTIONS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="border border-black text-center px-4 py-2">
                    {expandedDescriptions.includes(index) ? (
                      <>
                        <textarea
                          value={lead.description}
                          onChange={(e) =>
                            setLeadData((prevRecords) =>
                              prevRecords.map((record, i) =>
                                i === index
                                  ? { ...record, description: e.target.value }
                                  : record
                              )
                            )
                          }
                          className="w-full border border-gray-300 rounded p-2"
                        />
                        <button
                          onClick={() => {
                            handleDescriptionUpdate(index);
                            setExpandedDescriptions((prev) =>
                              prev.filter((i) => i !== index)
                            );
                          }}
                          className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 mt-2"
                        >
                          Save
                        </button>
                        <button
                          onClick={() =>
                            setExpandedDescriptions((prev) =>
                              prev.filter((i) => i !== index)
                            )
                          }
                          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 mt-2 ml-2"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        {lead.description.length > 50
                          ? `${lead.description.substring(0, 50)}...`
                          : lead.description}
                        <button
                          onClick={() =>
                            setExpandedDescriptions((prev) => [...prev, index])
                          }
                          className="relative  text-blue-500 underline hover:text-blue-700 ml-2"
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  No leads found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for creating lead */}
      {showLeadModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-3xl rounded-lg shadow-lg p-6 mx-2 md:mx-0">
            {/* Modal Header */}
            <div className="flex justify-between items-center">
              <h3 className="text-lg bg-grey-800 font-semibold">Create Lead</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShowLeadModal(false)} // Close modal when clicked
              >
                &times;
              </button>
            </div>

            {/* Form */}
            <form onSubmit={(e) => handleLeadSubmit(e)} className="space-y-4">
              {/* Grid Layout for Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    placeholder="Enter name"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Contact
                  </label>
                  <input
                    type="text"
                    name="contact"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    placeholder="Enter contact number"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    placeholder="Enter email"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Gender
                  </label>
                  <select
                    name="gender"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    required
                  >
                    <option value="" disabled selected>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Type
                  </label>
                  <select
                    name="type"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    required
                  >
                    <option value="" disabled selected>
                      Select Type
                    </option>
                    <option value="Services">Services</option>
                    <option value="Courses">Courses</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    placeholder="Enter Description"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Assigned To
                  </label>
                  <select
                    name="assign"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    required
                  >
                    <option value="" disabled selected>
                      Select Assign
                    </option>
                    {/* Map through employee names and create an option for each */}
                    {employees.map((employee, index) => (
                      <option key={index} value={employee}>
                        {employee}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Status
                  </label>
                  <select
                    name="status"
                    className="w-full border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-500"
                    required
                  >
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="Pending">Pending</option>
                    <option value="Contacted">Contacted</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Interested">Interested</option>
                    <option value="Not Interested">Not Interested</option>
                    <option value="In Future">In Future</option>
                    <option value="Won">Won</option>
                  </select>
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end space-x-4 mt-4">
                <button
                  type="button"
                  onClick={() => setShowLeadModal(false)} // Close modal on cancel
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
                >
                  {isCreatingLead ? "Creating..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadsDashboard;
