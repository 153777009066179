import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logowhite.png";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

import "react-datepicker/dist/react-datepicker.css";

import {
  FaHome,
  FaComments,
  FaSignOutAlt,
  FaBuilding,
  FaUser,
  FaCalendarAlt,
  FaPaperclip,
  FaTasks,
  FaBell,
  FaDatabase,
} from "react-icons/fa";
import ClockTime from "./ClockTime.js";
import LeadsDashboard from "../LeadsDashboard/index.js";
import Chat from "./Chat.js";
const EmployeeDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState("Home");
  const [taskRecords, setTaskRecords] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [employees, setEmployees] = useState();
  const [companies, setCompany] = useState();
  const [readNotificationIds, setReadNotificationIds] = useState(() => {
    const saved = localStorage.getItem("readNotifications");
    return saved ? JSON.parse(saved) : [];
  });

  const [taskFormData, setTaskFormData] = useState({
    task: "",
    company: "",
    employeeName: "",
    taskDate: new Date(),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (taskRecords) {
      localStorage.setItem("taskRecords", JSON.stringify(taskRecords));
    }
  }, [taskRecords]);

  useEffect(() => {
    const storedTasks = localStorage.getItem("taskRecords");
    if (storedTasks) {
      setTaskRecords(JSON.parse(storedTasks));
    }
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user || !user.name) {
          console.error("User name is missing in localStorage.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/tasks`,
          { params: { employeeName: user.name } }
        );
        setTaskRecords(() => {
          const updatedRecords = response.data;
          localStorage.setItem("taskRecords", JSON.stringify(updatedRecords));
          return updatedRecords;
        });
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchFeedback = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.name) {
        console.error("User name is missing in localStorage.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/uploads`,
          { params: { employeeName: user.name } }
        );
        // console.log("Fetched feedback:", response.data);
        const filteredFeedback = response.data.filter(
          (feedback) =>
            feedback.employeeName === user.name && feedback.feedback !== ""
        );
        setFeedbackData((prevFeedback) => {
          if (
            JSON.stringify(prevFeedback) !== JSON.stringify(filteredFeedback)
          ) {
            return filteredFeedback;
          }
          return prevFeedback;
        });
        if (filteredFeedback.length === 0) {
          // console.log("No feedback available for this user.");
        }
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    };

    const fetchNotifications = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.name) {
        console.error("User name is missing in localStorage.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/addnotifications`,
          { params: { username: user.name } }
        );
        const fetchedNotifications = response.data.map((notification) => ({
          ...notification,
          read: readNotificationIds.includes(notification._id),
        }));
        setNotifications(fetchedNotifications);
        const unreadCount = fetchedNotifications.filter((n) => !n.read).length;
        setUnreadNotifications(unreadCount);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchTasks();
    fetchFeedback();
    fetchNotifications();
    // const interval = setInterval(() => {
    //   // fetchTasks();
    //   fetchFeedback();
    //   // fetchNotifications();
    // }, 10000); // 10000ms = 10 seconds

    // // Cleanup interval on component unmount
    // return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/employees`
        );
        const filteredEmployees = response.data.filter(
          (employee) => employee.role === "employee"
        );

        // Map to get only employee names
        const employeeNames = filteredEmployees.map(
          (employee) => employee.name
        );

        const companyNames = response.data
          .filter((item) => item.company)
          .map((item) => item.company);
        setEmployees(employeeNames);
        setCompany(companyNames);
      } catch (error) {
        const axiosError = error;
        alert(
          axiosError?.response?.data &&
            typeof axiosError.response.data === "string"
            ? axiosError.response.data
            : "Error fetching employees"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    } else {
      navigate("/loginPopup");
    }
  }, [navigate]);
  if (loading) return <p>Loading tasks...</p>;

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/loginPopup");
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleChange = (e, setFormData) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setTaskFormData((prev) => ({ ...prev, taskDate: date || new Date() }));
  };

  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
    const newReadIds = notifications.filter((n) => !n.read).map((n) => n._id);
    const updatedReadIds = [...readNotificationIds, ...newReadIds];
    setReadNotificationIds(updatedReadIds);
    localStorage.setItem("readNotifications", JSON.stringify(updatedReadIds));
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true }))
    );

    setUnreadNotifications(0);
  };

  const handleUrlChange = (index, fileUrl) => {
    setTaskRecords((prevRecords) =>
      prevRecords.map((record, i) =>
        i === index ? { ...record, url: fileUrl.trim() } : record
      )
    );
  };

  const handleStatusChange = (index, newStatus) => {
    const updatedRecords = taskRecords.map((record, i) =>
      i === index ? { ...record, status: newStatus } : record
    );
    setTaskRecords(updatedRecords);
  };
  const updateTaskRecords = (newRecords) => {
    setTaskRecords(newRecords);
    localStorage.setItem("taskRecords", JSON.stringify(newRecords));
  };

  const handleSubmitTask = async (index) => {
    const updatedTask = taskRecords[index];
    console.log("Task to submit:", updatedTask);

    try {
      // Create payload
      const payload = {
        taskId: updatedTask.taskId,
        task: updatedTask.task,
        employeeName: updatedTask.employeeName,
        company: updatedTask.company,
        taskDate: updatedTask.taskDate,
        status: updatedTask.status || "Pending",
        url: updatedTask.url || "",
        // isSubmitted: true,
      };

      if (updatedTask.uploadsId) {
        // console.log("Updating existing task:", updatedTask.uploadsId);
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URI}/uploads/${updatedTask.uploadsId}`,
          payload
        );

        if (response.data.success) {
          Swal.fire({
            icon: "success",
            title: "Task Updated",
            text: "Task updated successfully!",
            timer: 2000,
          });
          setTaskRecords((prevRecords) =>
            prevRecords.map((record, i) =>
              i === index ? { ...record, ...response.data.data } : record
            )
          );
        }
      } else {
        // Create new task
        // console.log("Creating new task");
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/uploads`,
          payload
        );

        // console.log("Submit response:", response.data);

        if (response.data.success) {
          setTaskRecords((prevRecords) => {
            const newRecords = [...prevRecords];
            newRecords[index] = {
              ...newRecords[index],
              uploadsId: response.data.taskId,
              // isSubmitted: true,
              ...response.data.data,
            };
            return newRecords;
          });
          Swal.fire({
            icon: "success",
            title: "Task Submitted",
            text: "Task submitted successfully!",
            timer: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      alert(error.response?.data?.message || "Failed to handle task");
    }
  };
  const handleUpdateTask = async (index) => {
    const taskData = taskRecords[index];

    if (!taskData.uploadsId) {
      Swal.fire({
        icon: "error",
        title: "Cannot Update",
        text: "This task hasn't been submitted yet",
        timer: 2000,
      });
      return;
    }
    try {
      const payload = {
        id: taskData.uploadsId,
        status: taskData.status || "Pending",
        url: taskData.url || "",
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/uploads/${taskData.uploadsId}`,
        payload
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Task Updated",
          text: "Task updated successfully!",
          timer: 2000,
        });
        updateTaskRecords((prevRecords) =>
          prevRecords.map((record) =>
            record.uploadsId === taskData.uploadsId
              ? { ...record, ...response.data.data }
              : record
          )
        );
      }
    } catch (error) {
      console.error("Update error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to update task",
        timer: 2000,
      });
    }
  };

  const handleTaskSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const taskData = {
      task: taskFormData.task,
      company: taskFormData.company,
      employeeName: taskFormData.employeeName,
      taskDate: taskFormData.taskDate.toISOString(),
    };

    try {
      // Create a new task
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/tasks`,
        taskData
      );
      setTaskRecords([...taskRecords, response.data]);

      // Reset form and close modal
      setTaskFormData({
        task: "",
        company: "",
        employeeName: "",
        taskDate: new Date(),
      });
      setShowTaskModal(false);

      // Show success message
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Task created successfully!",
        timer: 2000,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data || "Failed to create task",
        timer: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="bg-gray-800 text-white w-64 h-screen flex flex-col">
        <div className="flex items-center justify-center p-4">
          <img src={logo} alt="Logo" className="w-20 h-18 " />
        </div>
        <div className="flex flex-col px-4 py-6 overflow-y-auto">
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => handleSectionChange("Home")}
          >
            <FaHome className="mr-2" /> Home
          </button>
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => handleSectionChange("Chat")}
          >
            <FaComments className="mr-2" /> Chat
          </button>
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => handleSectionChange("AdminFeedback")}
          >
            <FaComments className="mr-2" /> Admin Feedback
          </button>
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => handleSectionChange("LeadRecord")}
          >
            <FaDatabase className="mr-2" /> Leads Record
          </button>
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={handleLogout}
          >
            <FaSignOutAlt className="mr-2" /> Logout
          </button>
        </div>
      </div>

      <div className="flex-1 flex flex-col overflow-y-auto bg-blue-100">
        {/* Dashboard Header */}
        <div className="flex justify-between items-center px-6 py-4 bg-white shadow-md relative">
          <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">
            {activeSection === "Chat" && "Chat Box"}
            {activeSection === "AdminFeedback" && "Admin Feedback"}
            {activeSection === "LeadRecord" && "Lead Record"}
            {activeSection === "Home" && "Employee Dashboard"}
          </h1>

          <div className="ml-auto relative ">
            <button
              onClick={handleBellClick}
              className="p-2 bg-gray-800 text-white rounded shadow hover:bg-gray-700"
            >
              <FaBell />
              {unreadNotifications > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                  {unreadNotifications}
                </span>
              )}
            </button>

            {showNotifications && (
              <div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-64 p-4">
                <h3 className="text-lg font-bold">Notifications</h3>
                {notifications.length === 0 ? (
                  <p>No notifications available.</p>
                ) : (
                  <ul className="mt-2">
                    {notifications.map((notification, index) => (
                      <li
                        key={notification._id || index}
                        className={`p-2 border-b ${
                          notification.read ? "text-gray-500" : "font-bold"
                        }`}
                      >
                        {notification.message}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="p-6">
          {activeSection === "Home" && (
            <div>
              <div className="mt-4 mb-4">
                <h1> !اسلام علیکم {user?.name}</h1>

                <ClockTime username={user?.name || ""} />
                <hr className="my-6 border-t border-black" />
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-2xl font-bold">Task Records</h2>
                  <div className="flex space-x-4">
                    {/* <button
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                      onClick={() => setShowLeadModal(true)}
                    >
                      Create Lead
                    </button> */}
                    <button
                      className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={() => setShowTaskModal(true)}
                    >
                      Create Task
                    </button>
                  </div>
                </div>

                {/* {showLeadModal && (
                  <LeadsDashboard onClose={() => setShowLeadModal(false)} />
                )} */}
                {showTaskModal && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                      <h2 className="text-2xl mb-4">
                        {editingTaskIndex !== null
                          ? "Edit Task"
                          : "Create Task"}
                      </h2>
                      <form onSubmit={handleTaskSubmit}>
                        <div>
                          <label>Task</label>
                          <input
                            type="text"
                            name="task"
                            value={taskFormData.task}
                            onChange={(e) => handleChange(e, setTaskFormData)}
                            className="w-full px-2 py-1 border rounded"
                          />
                        </div>
                        <div className="mt-4">
                          <label>Company Name</label>
                          <select
                            name="company"
                            value={taskFormData.company}
                            onChange={(e) => handleChange(e, setTaskFormData)}
                            className="w-full px-2 py-1 border rounded mt-4"
                          >
                            <option value="">Select Company</option>
                            {companies.map((companyName, index) => (
                              <option key={index} value={companyName}>
                                {companyName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mt-4">
                          <label>Employee Name</label>
                          <select
                            name="employeeName"
                            value={taskFormData.employeeName}
                            onChange={(e) => handleChange(e, setTaskFormData)}
                            className="w-full px-2 py-1 border rounded mt-4"
                          >
                            <option value="">Select Employee</option>
                            {employees.map((employee, index) => (
                              <option key={index} value={employee}>
                                {employee}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mt-4  form-element">
                          <label>Task Date</label>
                          <DatePicker
                            selected={taskFormData.taskDate}
                            onChange={handleDateChange}
                            className=" px-2 py-1 border rounded"
                          />
                        </div>
                        <button
                          type="submit"
                          className="bg-green-500 text-white px-4 py-2 rounded mt-4"
                        >
                          {editingTaskIndex !== null ? "Update" : "Submit"}
                        </button>
                        <button
                          type="button"
                          onClick={() => setShowTaskModal(false)}
                          className="bg-gray-500 text-white px-4 py-2 rounded mt-4 ml-2"
                        >
                          Close
                        </button>
                      </form>
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 py-4 px-4">
                  {taskRecords.map((record, index) => (
                    <div
                      key={index}
                      className="p-4 bg-white rounded-lg shadow-lg border hover:shadow-xl"
                    >
                      <p className="text-lg font-bold">ID#{record.taskId}</p>
                      <p className="text-lg font-bold">{record.task}</p>
                      <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                        <div className="flex items-center gap-2">
                          <FaBuilding className="text-gray-500" />
                          <span>Company:</span>
                        </div>
                        <span className="text-black font-semibold text-xs">
                          {record.company}
                        </span>
                      </div>
                      <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                        <div className="flex items-center gap-2">
                          <FaUser className="text-gray-500" />
                          <span>Employee Name:</span>
                        </div>
                        <span className="text-black font-semibold">
                          {record.employeeName}
                        </span>
                      </div>
                      <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                        <div className="flex items-center gap-2">
                          <FaCalendarAlt className="text-gray-500" />
                          <span>Task Date:</span>
                        </div>
                        <span className="text-black font-semibold">
                          {new Date(record.taskDate).toLocaleDateString()}
                        </span>
                      </div>

                      <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                        <div className="flex items-center gap-2">
                          <FaPaperclip className="text-gray-500" />
                          <span>Task URL:</span>
                        </div>
                        <input
                          type="text"
                          className=" border rounded p-1"
                          placeholder="Enter URL"
                          value={record.url || ""}
                          onChange={(e) =>
                            handleUrlChange(index, e.target.value)
                          }
                        />
                      </div>

                      <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                        <div className="flex items-center gap-2">
                          <FaTasks className="text-gray-500 text-lg" />
                          <label>Task Status:</label>
                        </div>
                        <div>
                          <select
                            value={record.status || "Not Yet"}
                            onChange={(e) =>
                              handleStatusChange(index, e.target.value)
                            }
                            className="p-2 border rounded flex-1"
                          >
                            <option value="Progress">Progress</option>
                            <option value="Not Yet">Not Yet</option>
                            <option value="Team Review">Team Review</option>
                            <option value="Completed">Completed</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4 flex justify-center gap-4">
                        {/* {!record.isSubmitted ? ( */}
                        <button
                          onClick={() => handleSubmitTask(index)}
                          className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                        >
                          Submit
                        </button>
                        {/* ) : ( */}
                        <button
                          onClick={() => handleUpdateTask(index)}
                          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                        >
                          Update
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {activeSection === "Chat" && (
            <div>
              <Chat username={user?.name || ""} />
            </div>
          )}
          {activeSection === "AdminFeedback" && (
            <div className="admin-feedback">
              <h2 className="text-xl font-bold mt-4 px-6 py-2">
                Admin Feedback
              </h2>
              {feedbackData.length === 0 ? (
                <p>No feedback available for this task.</p>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 py-4 px-4">
                  {feedbackData.map((feedadmin, index) => (
                    <div
                      key={index}
                      className="p-4 bg-white rounded-lg shadow-lg border hover:shadow-xl"
                    >
                      <p>
                        <strong>Employee Name:</strong>{" "}
                        {feedadmin.employeeName || "N/A"}
                      </p>
                      <p>
                        <strong>Task:</strong> {feedadmin.task || "N/A"}
                      </p>
                      <p>
                        <strong>Feedback:</strong>{" "}
                        {feedadmin.feedback || "No feedback"}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {activeSection === "LeadRecord" && (
            <div>
              <LeadsDashboard username={user?.name || ""} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeDashboard;
