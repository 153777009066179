// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { getClosest, getSiblings, slideToggle, slideUp } from '../../../utils';

// const MobileNavMenu = () => {
//   const onClickHandler = (e) => {
//     const target = e.currentTarget;
//     const parentEl = target.parentElement;
//     if (
//       parentEl?.classList.contains('menu-toggle') ||
//       target.classList.contains('menu-toggle')
//     ) {
//       const element = target.classList.contains('icon') ? parentEl : target;
//       const parent = getClosest(element, 'li');
//       const childNodes = parent.childNodes;
//       const parentSiblings = getSiblings(parent);
//       parentSiblings.forEach((sibling) => {
//         const sibChildNodes = sibling.childNodes;
//         sibChildNodes.forEach((child) => {
//           if (child.nodeName === 'UL') {
//             slideUp(child, 1000);
//           }
//         });
//       });
//       childNodes.forEach((child) => {
//         if (child.nodeName === 'UL') {
//           slideToggle(child, 1000);
//         }
//       });
//     }
//   };
//   return (
//     <nav className="site-mobile-menu">
//       <ul>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/'}>
//             <span className="menu-text">Home</span>
//           </NavLink>
//         </li>

//         <li>
//           <NavLink
//             to={process.env.PUBLIC_URL + '/services/social-media-marketing'}
//           >
//             <span className="menu-text">Social Media Marketing</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink
//             to={process.env.PUBLIC_URL + '/services/social-media-management'}
//           >
//             <span className="menu-text">Social Media Management</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink
//             to={
//               process.env.PUBLIC_URL +
//               '/services/search-engine-optimization-campaigns'
//             }
//           >
//             <span className="menu-text">
//               Search Engine Optimization and Campaigns
//             </span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink
//             to={
//               process.env.PUBLIC_URL +
//               '/services/digital-marketing-strategy-consultation'
//             }
//           >
//             <span className="menu-text">
//               Digital Marketing Strategy and Consultation
//             </span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink
//             to={
//               process.env.PUBLIC_URL +
//               '/services/marketing-automation-content-marketing'
//             }
//           >
//             <span className="menu-text">
//               Marketing Automation and Content Marketing
//             </span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink
//             to={process.env.PUBLIC_URL + '/services/account-based-marketing'}
//           >
//             <span className="menu-text">Account Based Marketing</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/services/company-branding'}>
//             <span className="menu-text">Company Branding</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink
//             to={process.env.PUBLIC_URL + '/services/influencer-marketing'}
//           >
//             <span className="menu-text">Influencer Marketing</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/services/content-creation'}>
//             <span className="menu-text">Content Creation</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/services/leads-generation'}>
//             <span className="menu-text">Leads Generation</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/services/graphic-designing'}>
//             <span className="menu-text">Graphic Designing</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/services/video-editing'}>
//             <span className="menu-text">Video Editing</span>
//           </NavLink>
//         </li>

//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/blog-grid'}>
//             <span className="menu-text">Blogs</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/contact'}>
//             <span className="menu-text">Contact Us</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/meeting'}>
//             <span className="menu-text">Meeting</span>
//           </NavLink>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default MobileNavMenu;

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { getClosest, getSiblings, slideToggle, slideUp } from '../../../utils';

// const MobileNavMenu = () => {
//   const onClickHandler = (e) => {
//     const target = e.currentTarget;
//     const parentEl = target.parentElement;
//     if (
//       parentEl?.classList.contains('menu-toggle') ||
//       target.classList.contains('menu-toggle')
//     ) {
//       const element = target.classList.contains('icon') ? parentEl : target;
//       const parent = getClosest(element, 'li');
//       const childNodes = parent.childNodes;
//       const parentSiblings = getSiblings(parent);
//       parentSiblings.forEach((sibling) => {
//         const sibChildNodes = sibling.childNodes;
//         sibChildNodes.forEach((child) => {
//           if (child.nodeName === 'UL') {
//             slideUp(child, 1000);
//           }
//         });
//       });
//       childNodes.forEach((child) => {
//         if (child.nodeName === 'UL') {
//           slideToggle(child, 1000);
//         }
//       });
//     }
//   };

//   return (
//     <nav className="site-mobile-menu">
//       <ul>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/'}>
//             <span className="menu-text">Home</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/about'}>
//             <span className="menu-text">About Us</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/meeting'}>
//             <span className="menu-text">Meeting</span>
//           </NavLink>
//         </li>
//         <li className="menu-toggle">
//           <span
//             className="text-white menu-text cursor-pointer mb-4"
//             onClick={onClickHandler}
//           >
//             Services
//           </span>
//           <ul className="sub-menu">
//             <li>
//               <NavLink
//                 to={process.env.PUBLIC_URL + '/services/social-media-marketing'}
//               >
//                 <span className="menu-text">Social Media Marketing</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={
//                   process.env.PUBLIC_URL + '/services/social-media-management'
//                 }
//               >
//                 <span className="menu-text">Social Media Management</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={
//                   process.env.PUBLIC_URL +
//                   '/services/search-engine-optimization-campaigns'
//                 }
//               >
//                 <span className="menu-text">
//                   Search Engine Optimization and Campaigns
//                 </span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={
//                   process.env.PUBLIC_URL +
//                   '/services/digital-marketing-strategy-consultation'
//                 }
//               >
//                 <span className="menu-text">
//                   Digital Marketing Strategy and Consultation
//                 </span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={
//                   process.env.PUBLIC_URL +
//                   '/services/marketing-automation-content-marketing'
//                 }
//               >
//                 <span className="menu-text">
//                   Marketing Automation and Content Marketing
//                 </span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={
//                   process.env.PUBLIC_URL + '/services/account-based-marketing'
//                 }
//               >
//                 <span className="menu-text">Account Based Marketing</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={process.env.PUBLIC_URL + '/services/company-branding'}
//               >
//                 <span className="menu-text">Company Branding</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={process.env.PUBLIC_URL + '/services/influencer-marketing'}
//               >
//                 <span className="menu-text">Influencer Marketing</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={process.env.PUBLIC_URL + '/services/content-creation'}
//               >
//                 <span className="menu-text">Content Creation</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={process.env.PUBLIC_URL + '/services/leads-generation'}
//               >
//                 <span className="menu-text">Leads Generation</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={process.env.PUBLIC_URL + '/services/graphic-designing'}
//               >
//                 <span className="menu-text">Graphic Designing</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to={process.env.PUBLIC_URL + '/services/video-editing'}>
//                 <span className="menu-text">Video Editing</span>
//               </NavLink>
//             </li>
//           </ul>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/blog-grid'}>
//             <span className="menu-text">Blogs</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to={process.env.PUBLIC_URL + '/contact'}>
//             <span className="menu-text">Contact Us</span>
//           </NavLink>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default MobileNavMenu;

//////////////////////////////////////////////
import React from "react";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import navLinks from "../../../data/navLinks";

const MobileNavMenu = () => {
  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (
      parentEl?.classList.contains("menu-toggle") ||
      target.classList.contains("menu-toggle")
    ) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 1000);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 1000);
        }
      });
    }
  };

  return (
    <nav className="site-mobile-menu">
      <ul className="flex flex-col">
        <li className="border-b border-gray-300">
          <NavLink
            to={process.env.PUBLIC_URL + "/"}
            className="block py-2 px-4 text-gray-700"
          >
            <span className="menu-text">Home</span>
          </NavLink>
        </li>
        <li className="border-b border-gray-300">
          <NavLink
            to={process.env.PUBLIC_URL + "/about"}
            className="block py-2 px-4 text-gray-700"
          >
            <span className="menu-text">About Us</span>
          </NavLink>
        </li>
        <li className="menu-toggle border-b border-gray-300">
          <span
            className="block py-2 px-4 text-white cursor-pointer"
            onClick={onClickHandler}
          >
            Services
          </span>
          <ul className="sub-menu pl-4">
            {navLinks
              .find((link) => link.label === "Services")
              .children.map((child, index) => (
                <li
                  key={child.label}
                  className={
                    index ===
                    navLinks.find((link) => link.label === "Services").children
                      .length -
                      1
                      ? ""
                      : "border-b border-gray-300"
                  }
                >
                  <NavLink
                    to={process.env.PUBLIC_URL + child.href}
                    className="block py-2 px-4 text-gray-700"
                  >
                    <span className="menu-text">{child.label}</span>
                  </NavLink>
                </li>
              ))}
          </ul>
        </li>
        <li className="border-b border-gray-300">
          <NavLink
            to={process.env.PUBLIC_URL + "/blog-grid"}
            className="block py-2 px-4 text-gray-700"
          >
            <span className="menu-text">Blogs</span>
          </NavLink>
        </li>

        <li className="border-b border-gray-300">
          <NavLink
            to={process.env.PUBLIC_URL + "/contact"}
            reloadDocument={true}
            className="block py-2 px-4 text-gray-700"
          >
            <span className="menu-text">Contact Us</span>
          </NavLink>
        </li>
        <li className="border-b border-gray-300">
          <NavLink
            to={process.env.PUBLIC_URL + "/meeting"}
            className="block py-2 px-4 text-gray-700"
          >
            <span className="menu-text">Meeting</span>
          </NavLink>
        </li>
        <li className="border-b border-gray-300">
          <NavLink
            to={process.env.PUBLIC_URL + "/loginPopup"}
            className="block py-2 px-4 text-gray-700"
          >
            <span className="menu-text">Login</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
